<template>
  <base-section top-inner-shadow class="background" space="200">
    <v-container :class="`${g_bLowerBr ? 'px-5' : ''}`">
      <v-row no-gutters>
        <v-col :class="`kda-ts-${g_bLowerBr ? '24' : '64'}pt wt-extrabold`">
          {{ m_strTitle }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :class="`kda-ts-${g_bLowerBr ? '12' : '28'}pt nunito wt-semibold`">
          {{ m_strDesc }}
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
export default {
  name: 'KDAConsultDescription',

  mixins: [BaseBreakpoint],

  computed: {
    m_strTitle() {
      return this.$vuetify.lang.t(`$vuetify.kda.consult.desc.title`);
    },
    m_strDesc() {
      return this.$vuetify.lang.t(`$vuetify.kda.consult.desc.desc`);
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.background {
  background-color: #fbfbfb;
}
</style>
